<template>
  <span :title="text" data-toggle="tooltip" ref="hint" :data-delay="delay" :data-placement="placement" :class="classes">
    <slot></slot>
  </span>
</template>

<script>

import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  name: 'Hint',
  props: {
    title: {
      type: String,
      required: true,
    },
    placement: {
      type: String,
      required: false,
      default: 'bottom',
    },
    delay: {
      type: Number,
      required: false,
      default: 300,
    },
    classes: {
      type: String,
      required: false,
    },
    show: {
      type: Boolean,
      required: false,
    },
    mode: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const i18n = useI18n()
    const hint = ref()
    onMounted(() => {
      window.$(function() {
        if (props.mode === 'manual') {
          window.$(hint.value).tooltip(props.show ? 'show' : 'hide')
        } else {
          window.$(hint.value).tooltip()
        }
      })
    })
    watch(() => props.show, (val) => {
      if (val) {
        if (props.mode === 'manual') {
          window.$(hint.value).tooltip('enable')
          window.$(hint.value).tooltip('show')
        } else {
          window.$(hint.value).tooltip('enable')
        }
      } else if (!val) {
        window.$(hint.value).tooltip('hide')
        window.$(hint.value).tooltip('disable')
      }
    })

    const text = computed(() => i18n.t(props.title))
    return {
      text: text,
      hint,
    }
  },
}
</script>
